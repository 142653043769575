



























































































































































.main-sidebar {
  overflow-y: auto;

  #main-logo {
    max-height: 30px;
  }
  .item-icon-wrapper {
    display: inline-block;
  }
  .dropdown-menu {
    display: block;
  }
}
