// CONTENT
$color-body-bg: #f5f6fa;
$color-text: #444;
$color-heading-text: #35495e;

// LINKS
$color-link-text: #39a275;
$color-link-text-active: $color-text;

// INPUTS
$color-input-border: lighten($color-heading-text, 50%);

// BUTTONS
$color-button-bg: $color-link-text;
$color-button-disabled-bg: darken(desaturate($color-button-bg, 20%), 10%);
$color-button-text: white;