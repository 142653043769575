


















































.title {
  text-align: center;
  padding: 10px 10px;
}
