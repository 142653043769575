// Adjustment style date-range

.date-range .vdp-datepicker {
  max-width: 150px;

  input {
    font-size: .6875rem !important;
    text-align: center;
  }
}
