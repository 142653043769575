






























































































.alert {
  position: fixed;
  z-index: 9999;
  min-width: 270px;
  margin: 1rem 0;
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
  &.top {
    top: 15px;
  }
  &.bottom {
    bottom: 15px;
  }
  &.left {
    left: 15px;
  }
  &.center {
    left: 50%;
  }
  &.right {
    right: 15px;
  }
}
