// Reboot / Shards Improvements

// HTML
html {
  font-size: 15px;
  font-weight: 500;

  // Adjust font sizes for 144dpi+ (including retina).
  @media (-webkit-min-device-pixel-ratio: 1.5),
  (min-resolution: 144dpi) {
    font-size: 16px;
    font-weight: 400;
  }
}

// Body
body {
  background: $body-background;
  font-size: $body-font-size;
  font-weight: 500;

  @media (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    font-size: 13px;
    font-weight: 400;
  }
}

h1 {
  @extend %typography-xxlarge;
}
h2 {
  @extend %typography-xlarge;
}
h3 {
  @extend %typography-large;
}
h4 {
  @extend %typography-medium;
}
h5,
h6 {
  @extend %typography-small;
}