
























































































































































.aj_pagination {
  margin: 0;
  padding: 13px 0;
  background: #fbfbfb;
  border-radius: 0 0 .375rem .375rem;
  ul {
    margin: 0;
    li {
      cursor: pointer;
    }
  }
  nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .pagination__count {
    width: auto!important;
    max-width: -webkit-max-content!important;
    max-width: -moz-max-content!important;
    max-width: max-content!important;
    margin: 0!important;
    padding: 0!important;
    margin-top: auto!important;
    margin-bottom: auto!important;
    color: #818ea3;
  }
}
