



















































































.nav-link {
  font-size: 16px;
  padding-top: 0.8rem;
  &:hover {
    cursor: pointer;
  }
}
