





















































































































































































.main-navbar {
  .dropdown-menu {
    display: block;
  }
  .nav-link {
    font-size: 16px;
    padding-top: 0.7rem;
    &:hover {
      cursor: pointer;
    }
  }
}
