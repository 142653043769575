









// Design variables and global utilities from src/design.
@import '@/design/custom_variables.scss';

.button {
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
}
